<template>
  <div class="login">
  </div>
</template>

<script>

export default {
  name: 'Login',
  components: {},
  mounted() {
    this.$auth.loginWithRedirect();
  },
  methods: {},
};
</script>
